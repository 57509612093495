import React, { useState, useEffect } from "react";
import { advertismentAction, userActions } from "../../actions";
import { apiConstants, bannerTypeConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
const publicIp = require("public-ip");

function AfterLoginAds(props) {
    const dispatch = useDispatch();
    const [timeSetter, setTimeSetter] = useState(15);
    const [defaultLoginLink, setDefaultLoginLink] = useState();
    const [randomAdvertisment, setRandomAdvertisment] = useState(null);
    const [adId, setAdId] = useState("");

    const { getRandomAdvertismentList, getSetting } = useSelector((state) => ({
        getRandomAdvertismentList: state.getRandomAdvertismentList,
        getSetting: state.getSetting,
    }));

    const fetchRandomList = async () => {
        let ipAddress = await publicIp.v4();
        let obj = {
            orderByAscDesc: -1,
            limitQuery: false,
            ipAddress: ipAddress,
            type: bannerTypeConstants.LOGIN_ADS,
            perPage: 1, // Fetch only 1 ad
        };
        dispatch(advertismentAction.getRandomAdvertismentList(apiConstants.GET_RANDOM_ADVERTISMENT, obj));
        dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
    };

    // Fetch random ad from API response
    useEffect(() => {
        if (getRandomAdvertismentList?.getRandomAdvertismentList?.data?.data) {
            const { data } = getRandomAdvertismentList.getRandomAdvertismentList.data.data;
            if (data.length > 0) {
                setRandomAdvertisment(data[0]); // Only show the first ad
                setAdId(data[0]._id);
            }
        }
    }, [getRandomAdvertismentList]);

    // Fetch setting (default ad link)
    useEffect(() => {
        if (getSetting?.isGetSetting && getSetting?.getSetting?.data) {
            const { data } = getSetting.getSetting;
            setDefaultLoginLink(data.data.textLink || "#");
        }
    }, [getSetting]);

    // Fetch ad list initially
    useEffect(() => {
        fetchRandomList();
    }, []);

    // Countdown timer
    useEffect(() => {
        if (defaultLoginLink || randomAdvertisment) {
            if (timeSetter > 0) {
                const timer = setTimeout(() => {
                    setTimeSetter(timeSetter - 1);
                }, 1000);
                return () => clearTimeout(timer);
            }
        }
    }, [timeSetter, defaultLoginLink]);

    // Default ad when no other ad is available
    const defaultLoginAd = () => {
        return defaultLoginLink && defaultLoginLink !== "#" ? (
            <iframe key="1" className="w-100 h-90vh" src={defaultLoginLink} title="Default Ad" />
        ) : (
            <div className="bg-primary"></div>
        );
    };

    return (
        <>
            {randomAdvertisment && timeSetter === 0 ? (
                <div className="p-2 after-login-ads blue-layout d-flex flex-row align-items-center justify-content-center text-white">
                    <b>Thanks for viewing the site</b>
                    <button className="ml-2 no-hover-btn link-btn font-weight-bold" onClick={() => props.closeAd(true)}>
                        Click here to go to Dashboard
                    </button>
                    {randomAdvertisment.link ? (
                        <button className="no-hover-btn ml-2 success-btn">
                            <a className="text-white font-weight-bold" href={randomAdvertisment.link} target="_blank" rel="noreferrer">
                                Open site in new window
                            </a>
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                <div className="after-login-ads bg-warning d-flex flex-column align-items-center justify-content-center text-dark">
                    <b>You will be automatically redirected to your area after {timeSetter} seconds</b>
                    <b>To Add your site here, click on the Login Ads link inside the members area!</b>
                    {randomAdvertisment && (
                        <b>
                            <a href={`/adverisment-report/${adId}`} target="_blank" rel="noreferrer">
                                Report Advertisement
                            </a>
                        </b>
                    )}
                </div>
            )}

            <div>
                <section
                    className="d-flex align-items-center justify-content-center"
                    style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundImage: `url('/assets/images/BGR.png')`,
                        height: "93.2vh",
                    }}
                >
                    {randomAdvertisment ? (
                        randomAdvertisment.files ? (
                            <a href={randomAdvertisment.link} target="_blank" rel="noreferrer">
                                <img className="ad-banner" src={randomAdvertisment.files} alt="Advertisement Banner" />
                            </a>
                        ) : (
                            <iframe key="ad-frame" className="w-100 h-90vh" src={randomAdvertisment.link} title={randomAdvertisment.bannerTitle || "Advertisement"} />
                        )
                    ) : (
                        defaultLoginAd()
                    )}
                </section>
            </div>
        </>
    );
}

export default AfterLoginAds;
